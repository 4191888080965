import { create } from 'zustand';
import moment from 'moment'
import SRCAlarmsAndAlertsBaobabTemplate from '@data/SRCToastPositionTemplates';
import { persist } from 'zustand/middleware'
// @ts-ignore
import _ from 'lodash';
import parsedAccommodationPriceLists from '../controllers/accommodationPriceLists';
import { produce } from 'immer';
import {
    RoomInfoModel,
} from '@common/modelDefinition';
import { devtools } from 'zustand/middleware';

interface StoreActions {
    setNestedProperty: (path: string[], value: any, pkName?: string) => void;
    deleteItem: (path: string[], pkeyName: string, pkValue: any) => any;
    getItem: (path: string[], pkeyName: string, pkValue: any) => any;
    addItem: (path: string[], newItem: any) => any;
}


interface StoreState {
    pageTitle: any;
    pagePath:any;
    pkeyMap: any;
    SRCEnums: any; //contains all relevant SRC enumerators
    model: any;
    modalData:any;
    authTokenInfo: any; //it is an object containing token and user informations. it is obtained on logon!
    modelForms: any;
    SRCAlarmsAndAlerts: any;
    socketConnection:any;
    uiStates: any;
    about:any;
    otherSettings: any;
    IOTatewayIntegration: any;
    locale: any;
    defaultCurrency: any;
    hiddenDocumentColumns: any;
    offlineThermalPrinterInfo: any;
    companyInfo: any;
    licence: any;
    alarmInterpreter: any;
    invoicingSettings: any;
    fiscal:any;
    version:any;
    state: any;
    keycloak:any;
    majmun:any
    monkeys:any;
    map:any;
    tag:any;
}

var parseRoomInfos = (roomInfoDataSet:RoomInfoModel[], SRCEnums:any) => {
    let parsedRoomInfo:RoomInfoModel[] = [];
    _.forEach(roomInfoDataSet, (r:RoomInfoModel) => {
        let tmp = _.clone(r);
        if (r.SRCSettings) {
            tmp.SRCSettings = JSON.parse(r.SRCSettings);
        }
        // if (r.SRCIOStates && SRCEnums.ioNamedEnum) {
        //     tmp.SRCStates = {
        //         openInputsAndClosedOutputs: [],
        //     };
        //     let ioKeys = Object.keys(SRCEnums.ioNamedEnum);
        //     for (let i = 0; i < ioKeys.length; i++) {
        //         let isInput = _.includes(SRCEnums.inputs, ioKeys[i]); //else are output
        //         let inputOpenOutputClosed = r.SRCIOStates & (1 << (31 - i));
        //         if (isInput) {
        //             if (!inputOpenOutputClosed) {
        //                 //CLOSED INPUT DETECTED
        //                 tmp.SRCStates.openInputsAndClosedOutputs.push(ioKeys[i]);
        //             }
        //         } else {
        //             if (inputOpenOutputClosed) {
        //                 //closed output detected
        //                 tmp.SRCStates.openInputsAndClosedOutputs.push(ioKeys[i]);
        //             }
        //         }
        //     }
        // }
        if (r.params) {
            tmp.params = JSON.parse(r.params);
        }

        tmp.tags = r.tags && _.isString(r.tags)
            ? _.map(r.tags.split(';'), (s:any) => {
                  return s.trim();
              })
            : [];
        parsedRoomInfo.push(tmp);
    });
    return parsedRoomInfo;
};
  

const useStore = create(
    devtools(persist<StoreState & StoreActions>(
        (set: any, get: any) => ({
    pageTitle: null,
    // monkeys,
    pagePath:null,

    pkeyMap: {},
    SRCEnums: {}, //contains all relevant SRC enumerators
    model: {
        //WARNING: Do not supply the same models in whole and partial part. baobabFunnel would broke
        RoomInfo: [],
        SmartRoomController: [],
        SRCProxy: [],
        DoorAccessPoint: [],
        WhiteList: [],
        Reservation: [],
        Tax: [],
        AccommodationPriceList: [],
        Currency: [],
        RoomAttribute: [],
        SosResponse: [],
        CleaningStatus: [],
        RoomEventTicket: [], //getActiveRoomEventTickets
        RequiredVisaMap: [],
        PosTerminal: [],
        Customer: [], //used rarely - only for condo mode for now
        CondoOwner: [],
        DocumentScan: [],
        FeatureFlag: [],
        CleaningStatusHeader: [], // include CleaningStatuses, RoomInfo, Maid1, Maid2, Maid3 - initialy get only today
        Role: [],
        PosDisplayConfig:[],
        ChannelManagerMap:[],
        PersistentNotificationSystem:[],
        TaskManagementHeader: {},
        TaskManagementItem: [],
    },
    modalData: {
        display: false,
        width: '', //modal-sm || modal-lg || modal-xl
        header: 'Modal window title',
        // content: null, //it may be an react class instance
        showBottomCloseButton: false,
        params: {}, //modal params used optionaly by modal specific modal internals
    },
    authTokenInfo: null, //it is an object containing token and user informations. it is obtained on logon!
    modelForms: {},
    SRCAlarmsAndAlerts: SRCAlarmsAndAlertsBaobabTemplate,
    socketConnection: {
        showConnectingScreen: true,
        connectingMessage: 'ESTABLISHING SERVER CONNECTION',
        heading: '...',
    },
    uiStates: {
        accommodationPriceListStack: {
            timelineYearsCount: 2, //or 2 - better always 1
            startFromYear: new Date().getFullYear(), //new Date(`${new Date().getFullYear()}`) //current or the next one....
        },
        dashboard: {
            showFromToFilter: false,
            showComponents: ['timeline', 'sidebar_dailyInfo'],
            tagsFilter: [],
        },
        reservationAdministration: {
            from: moment().add(-1, 'month').valueOf(),
            to: moment().add(1, 'month').valueOf(),
        },
        columnsNumber: 2,
        financialReport: {
            from: new Date(),
            to: new Date(),
        }
    },
    about: {
        adminUsersCount: 0, //MORA BITI 0 S RAZLOGOM. Zbog prve instalacije!!!!
    },
    otherSettings: null,
    IOTatewayIntegration: null,
    locale: null,
    defaultCurrency: null,
    hiddenDocumentColumns: null,
    offlineThermalPrinterInfo: null,
    companyInfo: null,
    licence: null,
    alarmInterpreter: null,
    invoicingSettings: null,
    fiscal: {
        softwareFiscal: null,
        fiscalGeneralSettings: null,
        // registerENU: null,
    },
    version: {
        version: 'xxx',
        DOCKER_ENV: false,
    },
    state: {
        userPermissions: {
            models: null,
            features: null,
            dashboardRoute: '/',
            fixedRoute: '',
            externalDashboardRoute: "",
            externalFixedRoute: "",
        },
        globalLoading: 0,
        selectedPosTerminalId: null,
        posTerminal: {
            //samo za potrebe sučelja pos terminala
            invoice: {}, //ovo je povratna informacija sa backend invoice controlera
            invoiceItems: [], //ovo je povratna ifnormacija sa backend invoice controlera
            filteredItems: null, //ovdje ću stavljati rezultate filtriranje artiakala koje trebam priakzati u glavnom prozoru
            activeItemsSelection: [], //ovdje je lista trenutno odabranih stavki od kojih se sastavlja pending račun (key value par) key je posItemId, value je količina
            previousInvoices: [], //lista prethodno izdanih računa za taj terminal
        },
        checkoutInvoice: {
            //za izradu računa - samo jedan checkout na jednom računalu
            invoicePreview: {
                invoice: {},
                invoiceItems: [],
            },
            invoice: {},
            invoiceItems: [],
            advanceInvoices: [], //vezane uz rezervaciju na checkoutu
            proformaInvoices: [], //vezane uz rezervaciju na checkoutu
            stornoInvoices: [], //vezane uz rezervaciju na checkoutu
            groupReservationsArray: [],
            reservation: null,
            availableForAdvancePayment: 0,
            posTerminalInvoices: [],
            checkoutDateMismatchs: [],
            genuineInvoices: [], //vezane uz rezervaciju na checkoutu
            subsequentConsumption: false, // naknadna potrošnja
            stayoverOverlap: { overlapReservations: [], option: null, type: null },
            minibarInvoices: [],
            invoiceStornoPreview: null,
            accommodationInvoice: null,
        },
        groupRes: {
            //za izradu grupnih rezervacija u formi grupnih rezervacija
            groupReservation: {},
            reservations: [],
        },
        reservationForm2: {
            activeReservationIndex: 0,
            reservations: [],
            groupReservation: {},
            invalidVisaIds: [],
            reloadUuid: null,
            selfCheckInInfo: null,
            selectedReservationIndex: 0,
        },
        invoicePresenter: {
            invoice: {},
            refInvoice: null,
            invoicePreview: {
                invoice: {},
                invoiceItems: [],
            },
            groupReservationsArray: [],
            stornoInvoices: [],
            groupInvoiceDetails: {},
            padding: 1,
        },
        generalSettings: {
            //za settingse
        },
        householdPlanForSelectedMaid: {},
        updatedCleaningStatuses:[],
        permissions: [],
        rolePermissions: [],
    },
    keycloak: {
        authenticated: false,
    },
    majmun:"početak",

    monkeys:{
        parsedRoomInfo: () => {            
            const roomInfo = get().model?.RoomInfo;
            const SRCEnums = get()?.SRCEnums
            return parseRoomInfos(roomInfo, SRCEnums);
        },
        parsedAccommodationPriceLists: () => {
            const accommodationPriceLists = get().model.AccommodationPriceList
            const timelineYearsCount = get().uiStates.accommodationPriceListStack.timelineYearsCount
            const startFromYear = get().uiStates.accommodationPriceListStack.startFromYear
        
            return parsedAccommodationPriceLists(
                accommodationPriceLists,
                startFromYear,
                timelineYearsCount
            );
        },
        parsedAndTagedRoomInfo: () => {
            const roomInfo = get().model.RoomInfo
            const SRCEnums = get().SRCEnums

            let parsedData = parseRoomInfos(roomInfo, SRCEnums);
            let response = [];
            let tags = _.uniq(_.flatten(_.map(parsedData, 'tags')));
            _.forEach(tags, (tag:any) => {
                response.push({
                    tag,
                    rooms: _.filter(parsedData, (p:any) => {
                        return _.includes(p.tags, tag);
                    }),
                });
            });
            response.push({
                tag: null,
                rooms: _.filter(parsedData, (p:any) => {
                    return p.tags.length === 0;
                }),
            });
            return _.orderBy(response, (r:any) => r.rooms.length, 'desc');
        },
        roomTags: () => {
            const roomInfo = get().model.RoomInfo;
            const SRCEnums = get().SRCEnums;
            let parsedData = parseRoomInfos(roomInfo, SRCEnums);
            let tags = _.uniq(_.flatten(_.map(parsedData, 'tags')));
            return tags;
        },
        companyInfo: () => {
            const licence = get().licence;
            const _companyInfo = get().companyInfo;

            let companyInfo = _.clone(_companyInfo);
            let decodedLicence = {};
            if (
                licence &&
                licence.licence &&
                licence.licence.decodedLicence &&
                licence.licence.decodedLicence.companyInfo
            ) {
                decodedLicence = licence.licence.decodedLicence.companyInfo;
            }
            _.forIn(decodedLicence, (val:any, key:any) => {
                companyInfo[key] = val;
            });

            return companyInfo;
        },
        condoOwnerMap: () => {
            const condoOwners  = get().model.CondoOwner;
            const result:any = {};
            condoOwners.forEach((c:any) => {
                result[c.id] = { ...c };
                result[c.id].roomInfoIds = c.condoRoomInfoIds
                    ? c.condoRoomInfoIds.split(',').map((i:any) => {
                          const targetId = i.split(':')[0];
                          return Number(targetId);
                      })
                    : [];
            });
            return result;
        },
        condoOwnerReceptionistUuidMap: () => {
            const condoOwners  = get().model.CondoOwner;
            const result:any = {};
            condoOwners.forEach((c:any) => {
                result[c.userUuid] = { ...c };
                result[c.userUuid].roomInfoIds = c.condoRoomInfoIds
                    ? c.condoRoomInfoIds.split(',').map((i:any) => {
                          const targetId = i.split(':')[0];
                          return Number(targetId);
                      })
                    : [];
            });
            return result;
        },
        filteredAccommodationPriceLists: () => {
            const accommodationPriceLists = get().model.AccommodationPriceList;
            const filterAccommodationPriceLists = accommodationPriceLists.filter((acc:any) => {
                return !acc.condoPricelist && acc.isActive && !acc.isDeleted;
            });
            return filterAccommodationPriceLists;
        },
        cleaningStatusHeadersWithCleaningStatuses: () => {
            const cleaningStatusHeaders = get().model.CleaningStatusHeader
            const cleaningStatuses = get().model.CleaningStatus

            let newData = [];
            for (const cleaningStatusHeader of cleaningStatusHeaders) {
                const newCleaningStatusHeader = { ...cleaningStatusHeader };
                newCleaningStatusHeader.CleaningStatuses = cleaningStatuses.filter(
                    // pregazio postojeće sa ažuriranim sa backenda
                    (cs:any) => cs.cleaningStatusHeaderId === cleaningStatusHeader.id
                );
                newData.push(newCleaningStatusHeader);
            }
            return newData;
        }
    },

    map: {
        RoomInfo: () => {
            const RoomInfo = get().model.RoomInfo
            const res:any = {};
			RoomInfo.forEach((p:any)=>{
				res[p.id] = p;
			})
			return res;
        },
        SmartRoomController: () => {
            const SmartRoomController = get().model.SmartRoomController
            const res:any = {};
			SmartRoomController.forEach((p:any)=>{
				res[p.id] = p;
			})
			return res;
        },
        Reservation: () => {
            const Reservation = get().model.Reservation
            const res:any = {};
			Reservation.forEach((p:any)=>{
				res[p.id] = p;
			})
			return res;
        },
        Tax: () => {
            const Tax = get().model.Tax
            const res:any = {};
			Tax.forEach((p:any)=>{
				res[p.id] = p;
			})
			return res;
        },
    },

    tag: {
        RoomInfo: () => {
            const RoomInfo = get().model.RoomInfo
            const arrayOfTags:any[] = [];
            RoomInfo.forEach((p:any) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });
            const roomsTags:any[] = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o:any) => {
                    if (!roomsTags.includes(o)) {
                        roomsTags.push(o);
                    }
                });
            });
            return roomsTags;
        },
        AccommodationPriceList: () => {
            const AccommodationPriceList = get().model.AccommodationPriceList
            const arrayOfTags:any[] = [];
            AccommodationPriceList.forEach((p:any) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });

            let accommodationsTags:any[] = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o:any) => {
                    if (!accommodationsTags.includes(o)) {
                        accommodationsTags.push(o);
                    }
                });
            });
            return accommodationsTags;
        },
        WhiteList: () => {
            const WhiteList = get().model.WhiteList
            const arrayOfTags:any[] = [];
            WhiteList.forEach((p:any) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });

            const whiteListsTags:any[] = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o:any) => {
                    if (!whiteListsTags.includes(o)) {
                        whiteListsTags.push(o);
                    }
                });
            });

            return whiteListsTags;
        },
    },
    
    setNestedProperty: (path: any, value: any, pkName = undefined) => {
        set(produce((draftState) => {
          let currentState:any = draftState;
          // Traverse the path until the second-to-last element
          for (let i = 0; i < path.length - 1; i++) {
            currentState = currentState[path[i]];
            if (!currentState) return; // Exit early if any intermediate state is undefined
          }          
    
          // Replace the object with the specified ID if key is provided
          if (pkName) {
            const index = currentState?.[path?.[path?.length - 1]]?.findIndex((obj:any) => obj[pkName] === value[pkName]);
            if (index !== -1) {
              currentState[path[path.length - 1]][index] = value;
            }
          } else {
            // Set the value for the property
            currentState[path[path.length - 1]] = value;
          }
        }));
      },

    deleteItem: (path: any, pkeyName: any, pkValue: any) => {
        set((state: any) => {
            return produce(state, (draftState:any) => {
                if (pkeyName) {
                    const array = _.get(draftState, path.join("."));
                    const index = _.findIndex(array, { [pkeyName]: pkValue });
                    if (index !== -1) {
                        array.splice(index, 1); // Remove the item from the array
                    }
                }
            });
        });
    },

    getItem: (path: any, pkeyName: any, pkValue: any) => {    
        const array = _.get(get(), path.join("."));
        const index = _.findIndex(array, { [pkeyName]: pkValue });
        if (index !== -1) {
            return array[index]
        }
    },

    addItem: (path: any, newItem: any) => {
        set((state: any) => {
            return produce(state, (draftState:any) => {
                const existingArray = _.get(draftState, path, []);
                existingArray.push(newItem);
                _.set(draftState, path.join('.'), existingArray);
            });
        });
    },
}),
    {
        name: 'hologic-storage',
        //@ts-ignore
        partialize: (state:any) => ({ //ovo ispod se kesira
            // majmun: state.majmun, 
            authTokenInfo: state.authTokenInfo,
            locale: state.locale,
            licence: state.licence,
            keycloak: state.keycloak,
        }),
    }
))
);

export default useStore;
