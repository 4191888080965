import React from 'react';
import translate from '@data/translations';
import moment from 'moment';
import numbro from 'numbro';
import {
    InvoiceConclusionItemModel,
    InvoiceConclusionModel,
    // UserInfo,
} from '@common/modelDefinition';
import useStore from '@data/state/zustand';
import { getGroupedInvoiceConclusionItemsByClientType } from './utils';

export enum InvoiceTypeEnumText {
    'Geniune invoices',
    'Advance invoices',
    'Proforma invoices',
    'Proforma dispatched invoices',
}
interface InvoiceConclusionReportProps {
    invoiceConclusion: InvoiceConclusionModel;
    companyInfo: any;
}

function InvoiceConclusionReport(props: InvoiceConclusionReportProps) {
    const { invoiceConclusion, companyInfo } = props;
    const defaultCurrency: string = useStore((appState: any) => appState.defaultCurrency);
    const systemLocale: string = useStore((appState: any) => appState.locale);

    
    const groupedInvoiceConclusionItemsByClientType = getGroupedInvoiceConclusionItemsByClientType(
        invoiceConclusion?.InvoiceConclusionItems ? invoiceConclusion.InvoiceConclusionItems : []
    );
    return (
        <div className="">
            <div className="col-12 mt-3">
                <h5 className="mt-3 pointer text-center uppercase p-2">
                    {<b>{translate('Closing revenue report')}</b>}{' '}
                </h5>
            </div>
            <div className="m-auto" style={{ width: '900px' }}>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Company name')}</div>
                    <div className="col-6 text-right">{companyInfo.name}</div>
                </div>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Id number')}</div>
                    <div className="col-6 text-right">{companyInfo.idNumber}</div>
                </div>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Address')}</div>
                    <div className="col-6 text-right">{companyInfo.address}</div>
                </div>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Post office number')}</div>
                    <div className="col-6 text-right">
                        {companyInfo.postOfficeNumber} {companyInfo.town}
                    </div>
                </div>
                <br />

                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Document type')}</div>
                    <div className="col-6 text-right">
                        {invoiceConclusion.isPending
                            ? translate('Invoice reconciliation')
                            : translate('Invoice conclusion')}
                    </div>
                </div>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Time')}</div>
                    <div className="col-6 text-right">
                        {invoiceConclusion.timestamp
                            ? moment(invoiceConclusion.timestamp).locale(systemLocale).format('lll')
                            : moment().locale(systemLocale).format('lll')}
                    </div>
                </div>
                <div className="row custom-list-item-report">
                    <div className="col-6">{translate('Operator')}</div>
                    <div className="col-6 text-right">{invoiceConclusion.operatorName}</div>
                </div>
                {Object.keys(groupedInvoiceConclusionItemsByClientType).map((clientType) => {
                    const invoiceConclusionItems = groupedInvoiceConclusionItemsByClientType[clientType];
                    return (
                        <div>
                            <div className="custom-list-header mt-32p" style={{ fontSize: '20px' }}>
                                R{clientType} {translate('Invoices')}
                            </div>
                            <div>
                                {invoiceConclusionItems.map((it: InvoiceConclusionItemModel, key: number) => {
                                    let totalVat = 0;
                                    if (
                                        (it?.invoiceCount || it?.stornoInvoiceCount) &&
                                        it.invoiceType !== InvoiceTypeEnumText['Proforma invoices']
                                    ) {
                                        return (
                                            <div key={key}>
                                                <br />
                                                <div className="row custom-list-item-report">
                                                    <div className="col-12 text-center">
                                                        <b>{translate(InvoiceTypeEnumText[it.invoiceType])}</b>
                                                    </div>
                                                </div>

                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Cash')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.cash).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Card')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.card).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>

                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Virman')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.virman).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Proforma')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.proformaDispatched).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Total revenue')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.total).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Total revenue in cash')}</div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.cashTotal).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">
                                                        {translate('Total revenue in non cash')}
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        {numbro(it.nonCashTotal).format({
                                                            mantissa: 2,
                                                            trimMantissa: false,
                                                            thousandSeparated: true,
                                                        })}{' '}
                                                        {defaultCurrency}
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Invoice count')}</div>
                                                    <div className="col-6 text-right">{it.invoiceCount}</div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-6">{translate('Storno invoice count')}</div>
                                                    <div className="col-6 text-right">{it.stornoInvoiceCount}</div>
                                                </div>
                                                <br />
                                                <div className="row custom-list-item-report">
                                                    <div className="col-12">
                                                        <b>{translate('Tax recapitulation')}</b>
                                                    </div>
                                                </div>
                                                <div className="row custom-list-item-report">
                                                    <div className="col-3">
                                                        <b>{translate('Tax group')}</b>
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        <b>{translate('Basis')}</b>
                                                    </div>
                                                    <div className="col-3  text-right">
                                                        <b>{translate('Tax')}</b>
                                                    </div>
                                                    <div className="col-3  text-right">
                                                        <b>{translate('Tax2')}</b>
                                                    </div>
                                                </div>
                                                {it?.taxRecapitulation?.map((tax, key) => {
                                                    totalVat += tax.totalTaxValue;
                                                    return (
                                                        <div key={key}>
                                                            <div className="row custom-list-item-report">
                                                                <div className="col-3">{translate(tax.taxGroup)}</div>
                                                                <div className="col-3  text-right">
                                                                    {numbro(tax.base).format({
                                                                        mantissa: 2,
                                                                        trimMantissa: false,
                                                                        thousandSeparated: true,
                                                                    })}{' '}
                                                                    {defaultCurrency}
                                                                </div>
                                                                <div className="col-3  text-right">
                                                                    {numbro(tax.taxValue1).format({
                                                                        mantissa: 2,
                                                                        trimMantissa: false,
                                                                        thousandSeparated: true,
                                                                    })}{' '}
                                                                    {defaultCurrency}
                                                                </div>
                                                                <div className="col-3 text-right">
                                                                    {numbro(tax.taxValue2).format({
                                                                        mantissa: 2,
                                                                        trimMantissa: false,
                                                                        thousandSeparated: true,
                                                                    })}{' '}
                                                                    {defaultCurrency}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div className="row custom-list-item-report">
                                                    <div className="col-3">
                                                        <b>{translate('Total VAT')}</b>
                                                    </div>
                                                    <div className="col-3 text-right"></div>
                                                    <div className="col-3  text-right"></div>
                                                    <div className="col-3  text-right">
                                                        <b>
                                                            {' '}
                                                            {numbro(totalVat).format({
                                                                mantissa: 2,
                                                                trimMantissa: false,
                                                                thousandSeparated: true,
                                                            })}{' '}
                                                            {defaultCurrency}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default InvoiceConclusionReport;
